import type { IHttpClient } from '@wix/fe-essentials-viewer-platform/http-client'
import { SessionServiceAPI, METASITE_APP_DEF_ID } from '@wix/thunderbolt-symbols'
import { Fieldset } from '../types'
import { Set, Member } from './memberTypes'
import { toVeloMember } from './memberMapper'
import { FedopsLogger } from '@wix/fe-essentials-viewer-platform/dist/types/exports/fedops'
import { reportSiteMemberIdWithoutSmToken } from '@wix/thunderbolt-commons'

export class MembersSdk {
	private readonly isLoggedIn: boolean
	constructor(
		private readonly sessionService: SessionServiceAPI,
		private readonly httpClient: IHttpClient,
		private readonly isPreviewMode: boolean,
		private readonly siteMemberIdInsteadSmToken: boolean,
		private readonly logger?: FedopsLogger
	) {
		this.isLoggedIn = !!(
			sessionService.getSmToken() ||
			(this.siteMemberIdInsteadSmToken && sessionService.getSiteMemberId())
		)
	}

	public async getMyMember(sets: Array<Fieldset> = ['FULL']) {
		reportSiteMemberIdWithoutSmToken(
			this.logger,
			this.sessionService.getSiteMemberId(),
			this.sessionService.getSmToken()
		)
		if (!this.isPreviewMode && !this.isLoggedIn) {
			return undefined
		}
		const fieldsets = sets?.map((fieldset) => (fieldset === 'FULL' ? Set.FULL : Set.PUBLIC))
		const options = {
			params: { fieldsets },
			headers: this.getHeaders(),
		}
		const { data } = await this.httpClient.get<{ member: Member }>('/_api/members/v1/members/my', options)
		return toVeloMember(data.member)
	}

	public async joinCommunity() {
		reportSiteMemberIdWithoutSmToken(
			this.logger,
			this.sessionService.getSiteMemberId(),
			this.sessionService.getSmToken()
		)
		if (!this.isPreviewMode && !this.isLoggedIn) {
			return undefined
		}
		const options = {
			headers: this.getHeaders(),
		}
		const { data } = await this.httpClient.post<{ member: Member }>(
			'/_api/members/v1/members/join-community',
			{},
			options
		)
		return toVeloMember(data.member)
	}

	public async leaveCommunity() {
		reportSiteMemberIdWithoutSmToken(
			this.logger,
			this.sessionService.getSiteMemberId(),
			this.sessionService.getSmToken()
		)
		if (!this.isPreviewMode && !this.isLoggedIn) {
			return undefined
		}
		const options = {
			headers: this.getHeaders(),
		}
		const { data } = await this.httpClient.post<{ member: Member }>(
			'/_api/members/v1/members/leave-community',
			{},
			options
		)
		return toVeloMember(data.member)
	}

	private getHeaders() {
		const headers = {
			'x-wix-client-artifact-id': 'thunderbolt',
			authorization: this.sessionService.getInstance(METASITE_APP_DEF_ID),
		}
		return headers
	}
}
